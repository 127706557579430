import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Layout from '../component/layout/layout';
import { Link } from 'gatsby';
import React from 'react';
import SEO from '../component/seo';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';

class NotFoundPage extends React.Component {
  render() {
    return (
      <Layout>
        <SEO title='404: Not found' />
        <div className='error-page'>
          <FontAwesomeIcon icon={faExclamationTriangle} />
          <h1>404</h1>
          <h2>Page not Found</h2>
          <Link to='/'>
            <Button variant='btn' className='btn-danger-custom'>
              <span>Back</span>
            </Button>
          </Link>
        </div>
      </Layout>
    );
  }
}

export default NotFoundPage;
